import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { Tab } from 'react-bootstrap';
import ProductSchema from 'components/commercetools-ui/organisms/product/ProductSchema';
import { sendGTMEvent } from 'components/headless/GTMSnippet';
import { appLocales } from 'helpers/constants/appLocales';
import { PAGE_VIEW_EVENTS, USER_CATEGORY } from 'helpers/constants/seoConstants';
import { getLocalizationInfo } from 'project.config';
import { tabsEventKey } from 'types/ETabKeys';
import { useAccount } from 'frontastic';
import Brow from './Brow/Brow';
import { filterPDPBrowTabsMenu } from './Brow/utils';
import ProductOverviewTabs from './ProductOverviewTabs/ProductOverviewTabs';
import { IProductOverviewPageProps } from './types/IProductOverviewPageProps';
import { TBrow } from '../../../types/TBrow';
import SwiftypeMeta from '../../commercetools-ui/organisms/product/ProductDetails/components/SwiftypeMeta';
import SkipContent from '../atoms/SkipContent/SkipContent';
import ErrorPage from '../ErrorPage';
import Footer from '../Footer';
import Header from '../Header';
import PromoBanner from '../PromoBanner';

const ProductOverviewPage = ({ data }: IProductOverviewPageProps) => {
  const router = useRouter();
  const defaultTabKey = router.asPath.split('#')[1];
  const locale = getLocalizationInfo(router.locale).locale.split('@')[0];
  const [activeTabKey, setActiveTabKey] = useState(tabsEventKey.OVERVIEW);
  const [loadScript, setLoadScript] = useState(false);
  const { account, accountLoading } = useAccount();

  useEffect(() => {
    if (window !== undefined && !accountLoading) {
      sendGTMEvent({
        event: 'page_view',
        pageName: data?.product?.name,
        pageCategory: data?.product.categories?.map((category) => category.name).join(', '),
        pageType: PAGE_VIEW_EVENTS.PRODUCT_OVERVIEW.TYPE,
        userId: account?.accountId,
        userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      });
    }
  }, [accountLoading, accountLoading, data?.product?.name]);

  useEffect(() => {
    if (defaultTabKey) {
      setActiveTabKey(defaultTabKey);
      if (defaultTabKey === tabsEventKey.RATING_AND_REVIEW) {
        setLoadScript(true);
      }
    }
  }, [setActiveTabKey, setLoadScript, defaultTabKey]);

  const isProductError =
    !data ||
    !data.content ||
    !data.product ||
    !data.product.published ||
    Boolean(data.product.hidden) ||
    !data.product?.variants.length;

  const { content, product, fallback } = data;

  // Filters out the PDP Brow Tabs that have displayDefaultBlock set to 'false' or does not have content
  let browData: TBrow | undefined = undefined;

  if (Boolean(content.pdpBrowTabsMenu) && !isProductError) {
    browData = filterPDPBrowTabsMenu(content, product, locale);
  }

  const onSelectTabHandler = (eventKey: string) => {
    if (eventKey === tabsEventKey.OVERVIEW) {
      router.replace(router.asPath.split('#')[0]);
    }
    if (eventKey === tabsEventKey.RATING_AND_REVIEW) {
      setLoadScript(true);
    }
    return setActiveTabKey(eventKey);
  };

  if (isProductError) return <ErrorPage />;
  const metaDescription = product.metaDescription || product.description;
  const metaTitle = product.metaTitle || product.name;
  const searchKeywords = product.searchKeywords || [];

  return (
    <>
      {data.product && (
        <ProductSchema product={data.product} variant={data.product?.variants[0]} origin={data.origin} />
      )}
      <div data-testid="product-overview-page" className="nikon-product-overview-page">
        <SkipContent />
        {content.promoBanner && <PromoBanner data={content.promoBanner} />}
        <Header data={data.content.header} />
        <Head>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta name="keywords" content={searchKeywords.toString()} />

          <meta property="og:title" content={metaTitle} />
          <meta property="og:image" content={product.variants[0].representativeImage} />
          <meta property="og:description" content={metaDescription} />
        </Head>
        <SwiftypeMeta product={product} variant={product.variants[0]} />

        <main
          className="product-overview-page-main-content"
          data-testid="product-overview-page-main-content"
          id="nkn-main-content"
          tabIndex={-1}
        >
          <Tab.Container id="product-overview-tabs" defaultActiveKey={tabsEventKey.OVERVIEW} activeKey={activeTabKey}>
            {/* tab links */}
            {browData && locale !== appLocales.PT_BR && (
              <Brow
                data={browData}
                product={product}
                onSelectTab={onSelectTabHandler}
                isPdpOverview
                activeKey={activeTabKey}
              />
            )}

            {/* tab content */}
            <ProductOverviewTabs data={content} fallback={fallback} product={product} />
          </Tab.Container>
        </main>

        <Footer data={content.footer} />
        {loadScript && (
          <>
            <Script id="turn-to-reviews">{`window.TurnToCmd('initialize', 'reviews-summary');`}</Script>
          </>
        )}
      </div>
    </>
  );
};

export default ProductOverviewPage;
