import React, { FC } from 'react';
import { useRouter } from 'next/router';
import { IProductSchemaProps } from './types/IProductSchemaProps';
import { IMAGE_SOURCE } from '../../../../../helpers/constants/imageSources';
import { CurrencyHelpers } from '../../../../../helpers/currencyHelpers';
import { EAddToCartStatus } from '../../../../../types/TNikonProduct';

const schemaAvailabilityOptions = {
  [EAddToCartStatus.ADD_TO_CART]: 'InStock',
  [EAddToCartStatus.PREORDER]: 'PreOrder',
  [EAddToCartStatus.BACKORDER]: 'BackOrder',
  [EAddToCartStatus.OUT_OF_STOCK]: 'OutOfStock',
};

const ProductSchema: FC<IProductSchemaProps> = ({ product, variant, origin = null }) => {
  const isBrowser = typeof window !== 'undefined';
  const router = useRouter();
  const imageUnavailable = IMAGE_SOURCE.getUnavailableImage(router.locale);
  // we may render the schema in SSR, so we need to check if location is defined
  // if not, make sure to pass the origin prop from the server
  const currentStoreUrl = isBrowser ? location?.origin : origin;
  const storeLogo = `${currentStoreUrl}/favicon-192x192.png`;
  const productUrl = `${currentStoreUrl}${product?._url}/overview`;
  const {
    attributes: { isRefurbished },
  } = variant;
  const addToCartStatus = variant.addToCartStatus || EAddToCartStatus.ADD_TO_CART;

  const data = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: product.name,
    sku: variant.sku,
    url: productUrl,
    image: variant.representativeImage || variant.images[0] || imageUnavailable,
    description: product.description,
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: product.reviewRatingStatistics?.averageRating.toFixed(1).toString() || 0,
      ratingCount: product.reviewRatingStatistics?.count || 0,
    },
    offers: {
      '@type': 'Offer',
      priceCurrency: variant.price.currencyCode,
      price: CurrencyHelpers.formatPriceWithoutCurrencyCode(variant.price),
      itemCondition: !isRefurbished ? 'NewCondition' : 'RefurbishedCondition',
      availability: schemaAvailabilityOptions[addToCartStatus],
      url: productUrl,
      seller: {
        '@type': 'Corporation',
        name: 'Nikon',
        url: currentStoreUrl,
        logo: {
          '@type': 'ImageObject',
          url: storeLogo,
          width: '192',
          height: '192',
        },
      },
    },
    brand: {
      '@type': 'Brand',
      name: 'Nikon',
      url: currentStoreUrl,
      logo: {
        '@type': 'ImageObject',
        url: storeLogo,
        width: '192',
        height: '192',
      },
    },
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(data),
      }}
    />
  );
};

export default ProductSchema;
